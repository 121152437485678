import React from 'react'
import {Link} from 'gatsby'

import MainLayout from '../layout/mainLayout'
import Head from '../components/utils/head'


const NotFound = () => {
  return (
    <MainLayout>
      <Head title="404"/>
      <h1>Page not found</h1>
      <p><Link to="/">Go to FRONT PAGE</Link></p>
    </MainLayout>
  )
}

export default NotFound
